<template>
  <Header :key="key" :navigation="data?.navigation" />
  <!--  <MainMenu :navigation="data?.navigation" class="sticky top-16 hidden min-h-[49px] sm:block" />-->
  <div class="flex min-h-[calc(100dvh-57px)] scroll-pt-[57px] flex-col">
    <main :key="key" class="relative flex flex-1 flex-col overflow-x-clip">
      <slot />
    </main>
    <Footer :key="languageCode" :footer="data?.footer" />
  </div>
</template>
<script setup lang="ts">
import type { DefaultLayoutQueryVariables } from "@graphql";
import { DefaultLayoutDocument } from "@graphql";

import { useUserJwtClaims } from "~/composables/auth";
import { useChannel } from "~/src/channel/composables";

const jwtClaims = useUserJwtClaims();
const { languageCode } = useChannel();

const key = computed(() => `${languageCode.value}-${jwtClaims.value?.user_id ?? "guest"}`);

const { data } = await useAsyncQuery({
  query: DefaultLayoutDocument,
  variables: computed<DefaultLayoutQueryVariables>(() => ({
    languageCode: languageCode.value,
  })),
});
</script>
